import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createBlock as _createBlock } from "vue";
var _hoisted_1 = {
  id: "albinifooter"
};
var _hoisted_2 = {
  key: 0,
  class: "logo"
};
var _hoisted_3 = ["src"];
var _hoisted_4 = {
  class: "hlinks"
};
var _hoisted_5 = ["href"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_RouterLink = _resolveComponent("RouterLink");
  var _component_el_footer = _resolveComponent("el-footer");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.datafooter?.footer?.data, h => {
    return _openBlock(), _createBlock(_component_el_footer, {
      key: h.attributes
    }, {
      default: _withCtx(() => [h.image_footer?.data ? (_openBlock(), _createElementBlock("div", _hoisted_2, [_createVNode(_component_RouterLink, {
        to: '/' + _ctx.$root.language + '/welcome'
      }, {
        default: _withCtx(() => [_createElementVNode("img", {
          src: _ctx.$root.pathcms + h.image_footer?.data?.attributes?.url
        }, null, 8, _hoisted_3)]),
        _: 2
      }, 1032, ["to"])])) : _createCommentVNode("", true), _createElementVNode("div", _hoisted_4, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(h.links, link => {
        return _openBlock(), _createElementBlock("a", {
          href: link.link_url,
          class: _normalizeClass(link.link_url == _ctx.pageactive ? 'linkActive' + link.link_class : link.link_class),
          key: link.id
        }, _toDisplayString(link.link_title), 11, _hoisted_5);
      }), 128))])]),
      _: 2
    }, 1024);
  }), 128))]);
}