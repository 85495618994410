import { useQuery } from '@urql/vue';
import { defineComponent, ref, onMounted } from 'vue';
export default defineComponent({
  name: 'footer',
  props: {
    pageactive: String
  },
  data() {
    return {
      activePage: '',
      activeLang: $cookies.get("AlbiniSHOPLANG")
    };
  },
  setup() {
    var datafooter = null;
    var result = useQuery({
      query: `
      {
        footer(locale: "` + $cookies.get("AlbiniSHOPLANG") + `"){
            data{
                attributes{
                    image_footer{
                    data{
                        attributes{
                        url
                        }
                    }
                    }
                    links{
                    link_class
                    link_title
                    link_url
                    }
                }
            }
        }
    }`
    });
    var {
      __typename,
      ...rest
    } = result;
    return {
      fetchingfooter: result.fetching,
      datafooter: result.data ? result.data : null,
      errorfooter: result.error
    };
  },
  mounted: function () {
    var scriptName = "https://cdn.iubenda.com/iubenda.js",
      scriptName2 = "https://cdn.iubenda.com/cs/gpp/stub.js",
      scriptName3 = "https://cdn.iubenda.com/cs/iubenda_cs.js";
    setTimeout(function () {
      if (!document.querySelector("script[src*='" + scriptName + "']")) {
        var scriptglobal = document.createElement("script");
        scriptglobal.setAttribute("src", scriptName);
        document.getElementById('albinifooter')?.append(scriptglobal);
      }
      if (!document.querySelector("script[src*='" + scriptName2 + "']")) {
        var scriptvariable = document.createElement("script");
        scriptvariable.innerHTML = "var _iub = _iub || [];";
        document.body.appendChild(scriptvariable);
        if ($cookies.get("AlbiniSHOPLANG") == 'it') {
          _iub.csConfiguration = {
            "askConsentAtCookiePolicyUpdate": true,
            "countryDetection": true,
            "enableLgpd": true,
            "enableUspr": true,
            "floatingPreferencesButtonDisplay": "bottom-right",
            "invalidateConsentWithoutLog": true,
            "lgpdAppliesGlobally": false,
            "perPurposeConsent": true,
            "siteId": 2953541,
            "whitelabel": false,
            "cookiePolicyId": 82223029,
            "lang": "it",
            "banner": {
              "acceptButtonDisplay": true,
              "closeButtonDisplay": false,
              "customizeButtonDisplay": true,
              "explicitWithdrawal": true,
              "listPurposes": true,
              "position": "float-top-center",
              "rejectButtonDisplay": true
            }
          };
        } else {
          _iub.csConfiguration = {
            "askConsentAtCookiePolicyUpdate": true,
            "countryDetection": true,
            "enableLgpd": true,
            "enableUspr": true,
            "floatingPreferencesButtonDisplay": "bottom-right",
            "invalidateConsentWithoutLog": true,
            "lang": "en",
            "lgpdAppliesGlobally": false,
            "perPurposeConsent": true,
            "siteId": 2953541,
            "whitelabel": false,
            "cookiePolicyId": 31182186,
            "banner": {
              "acceptButtonDisplay": true,
              "closeButtonDisplay": false,
              "customizeButtonDisplay": true,
              "explicitWithdrawal": true,
              "listPurposes": true,
              "position": "float-top-center",
              "rejectButtonDisplay": true
            }
          };
        }
        var script = document.createElement("script");
        script.setAttribute("src", scriptName2);
        document.body.appendChild(script);
        var script2 = document.createElement("script");
        script2.setAttribute("src", scriptName3);
        script2.setAttribute("charset", "UTF-8");
        script2.setAttribute("async", "true");
        document.body.appendChild(script2);
      }
    }, 2000);
  }
});