import { HTTP } from "./http-common";
import BaseApi from "./http-common";

export default {
  getCart(){
    return BaseApi.search("/carts/detail");
  },
  getCartTotals(){
    return BaseApi.search("/carts/totals");
  },
  removeCart(){
    return BaseApi.delete("/carts/","");
  },

  removeItem(id){
    return BaseApi.delete("/carts/item/",id);
  },

  removeBook(id){
    return BaseApi.delete("/carts/item-book/",id);
  },

  addToCart(data) {
    return BaseApi.create("/carts/item", data);
  },

  update(data) {
    return BaseApi.update("/carts", '', data);
  },

  updateItem(data) {
    return BaseApi.update("/carts/item",'', data);
  },


  checkAvailability(data){
    return BaseApi.create("/carts/item-price", data);
  },

  completeOrder(data){
    return BaseApi.create("/carts/buy", data);
  },

  getBraintreeKeys(data) {
    return BaseApi.create("/carts/buy1", data);
  },

  addBookToCart(data) {
    return BaseApi.create("/carts/item-book", data);
  }
  

};
