import { createRouter, createWebHistory } from "vue-router";
const router = createRouter({
	history: createWebHistory(),
	routes: [
		{
			path: "/:locale",
			component: {
				template: "<RouterView />",
			},
			children: [
				{
					path: "welcome",
					name: "homepage",
					component: () => import("../views/homepage.vue"),
					meta: {
						title: "Benvenuto su Albini Group Online Shop",
						titleEN: "Welcome to Albini Group Online Shop",
						requiresAuth: true,
					},
				},
				{
					path: "guide-tour",
					name: "guidetour",
					component: () => import("../views/guide.vue"),
					meta: {
						title: "Benvenuto su Albini Group Online Shop - tour guidato",
						titleEN: "Welcome to Albini Group Online Shop - guide tour",
						requiresAuth: true,
					},
				},
				{
					path: "virtual-show-room/:brand?/:catalog?/:book?",
					name: "virtualshowroom",
					props: true,
					component: () => import("../views/virtualshowroom.vue"),
					meta: {
						title: "Benvenuto su Albini Group Online Virtual show room",
						titleEN: "Welcome to Albini Group Online Virtual show room",
						requiresAuth: true,
					},
				},
				{
					path: "public-catalogue/:ditta/:season/:brand/:collection/:book",
					name: "public-catalogue",
					props: true,
					component: () => import("../views/public-catalogue.vue"),
					meta: {
						title: "Benvenuto su Albini Group Online Virtual show room",
						titleEN: "Welcome to Albini Group Online Virtual show room",
						// no auth! public page
					},
				},
				{
					path: "public-product/:ditta/:code/:color",
					name: "public-product",
					props: true,
					component: () => import("../views/public-product.vue"),
					meta: {
						title: "Albini Group Online Shop - Dettaglio prodotto",
						titleEN: "Albini Group Online Shop - Detail product",
						// no auth! public page
					},
				},
				{
					path: "autologin",
					name: "impersonate",
					component: () => import("../views/impersonate.vue"),
					meta: {
						title: "Benvenuto su Albini Group Online Shop",
						titleEN: "Welcome to Albini Group Online Shop",
						requiresAuth: false,
					},
				},
				{
					path: "login",
					name: "login",
					component: () => import("../views/login.vue"),
					meta: {
						title: "Benvenuto su Albini Group Online Shop",
						titleEN: "Welcome to Albini Group Online Shop",
						requiresAuth: false,
					},
				},
				{
					path: "become-a-partner",
					name: "become-a-partner",
					component: () => import("../views/become-a-partner.vue"),
					meta: {
						title: "Albini Group Online Shop - Diventa nostro parner",
						titleEN: "Albini Group Online Shop - Become a partner",
						requiresAuth: false,
					},
				},
				{
					path: "fast-pay",
					name: "fastpayment",
					component: () => import("../views/fastpayment.vue"),
					meta: {
						title: "Albini Group Online Shop - Paga velocemente un tuo ordine",
						titleEN: "Albini Group Online Shop - Fast payment",
						requiresAuth: false,
					},
				},
				{
					path: "reset-password",
					name: "resetpassword",
					props: true,
					component: () => import("../views/resetpassword.vue"),
					meta: {
						title: "Albini Group Online Shop - Password dimenticata?",
						titleEN: "Albini Group Online Shop - Reset your password",
						requiresAuth: false,
					},
				},
				{
					path: "shop",
					name: "catalog",
					props: true,
					component: () => import("../views/catalog.vue"),
					meta: {
						title: "Albini Group Online Shop - Catalogo",
						titleEN: "Albini Group Online Shop - Catalog",
						requiresAuth: true,
					},
				},
				{
					path: "product/:prodid",
					name: "productdetail",
					props: true,
					component: () => import("../views/product.vue"),
					meta: {
						title: "Albini Group Online Shop - Dettaglio prodotto",
						titleEN: "Albini Group Online Shop - Detail product",
						requiresAuth: true,
					},
				},
				{
					path: "cart",
					name: "cart",
					component: () => import("../views/cart.vue"),
					meta: {
						title: "Albini Group Online Shop - Carrello",
						titleEN: "Albini Group Online Shop - Cart",
						requiresAuth: true,
					},
				},
				{
					path: "availability",
					name: "availability",
					component: () => import("../views/availability.vue"),
					meta: {
						title: "Albini Group Online Shop - Disponibilità",
						titleEN: "Albini Group Online Shop - Availability",
						requiresAuth: true,
					},
				},
				{
					path: "thanksyou/:idorder",
					name: "thanksyou",
					props: true,
					component: () => import("../views/thanksyou.vue"),
					meta: {
						title: "Albini Group Online Shop - Grazie !",
						titleEN: "Albini Group Online Shop - Thanks you !",
						requiresAuth: true,
					},
				},
				{
					path: "quick-order",
					name: "quickorder",
					component: () => import("../views/quickorder.vue"),
					meta: {
						title: "Albini Group Online Shop - Quick order",
						titleEN: "Albini Group Online Shop - Quick order",
						requiresAuth: true,
					},
				},
				{
					path: "maintenance",
					name: "maintenance",
					component: () => import("../views/maintenance.vue"),
					meta: {
						title: "Albini Group Online Shop - Maintenance",
						titleEN: "Albini Group Online Shop - Maintenance",
						requiresAuth: true,
					},
				},
				{
					path: "stories",
					name: "insights",
					component: () => import("../views/insights.vue"),
					meta: {
						title: "Albini Group Online Shop - News",
						titleEN: "Albini Group Online Shop - News",
						requiresAuth: true,
					},
				},
				{
					path: "stories/:slug",
					name: "insightsdetail",
					props: true,
					component: () => import("../views/insight.vue"),
					meta: {
						title: "Albini Group Online Shop - Dettaglio news",
						titleEN: "Albini Group Online Shop - News detail",
						requiresAuth: true,
					},
				},
				{
					path: "wishlist",
					name: "wishlist",
					props: true,
					component: () => import("../views/wishlist.vue"),
					meta: {
						title: "Albini Group Online Shop - Wishlist",
						titleEN: "Albini Group Online Shop - Wishlist",
						requiresAuth: true,
					},
				},
				{
					path: "search",
					name: "search",
					component: () => import("../views/search.vue"),
					meta: {
						title: "Albini Group Online Shop - Ricerca",
						titleEN: "Albini Group Online Shop - Search",
						requiresAuth: true,
					},
				},
				{
					path: "page/:slug",
					name: "page",
					props: true,
					component: () => import("../views/page.vue"),
					meta: {
						title: "Albini Group Online Shop - Pagina",
						titleEN: "Albini Group Online Shop - Page",
						requiresAuth: false,
					},
				},
				{
					path: "user-area",
					name: "user-area",
					component: () => import("../views/userarea/userarea.vue"),
					meta: {
						title: "Albini Group Online Shop - Area utente",
						titleEN: "Albini Group Online Shop - User area",
						requiresAuth: true,
					},
					children: [
						{
							path: "profile",
							name: "profile",
							props: true,
							component: () => import("../views/userarea/profile.vue"),
							meta: {
								title: "Albini Group Online Shop - Area utente - Profilo",
								titleEN: "Albini Group Online Shop - User Area - Profile",
								requiresAuth: true,
							},
						},
						{
							path: "preferences",
							name: "preferences",
							props: true,
							component: () => import("../views/userarea/preferences.vue"),
							meta: {
								title: "Albini Group Online Shop - Area utente - Preferenze",
								titleEN: "Albini Group Online Shop - User Area - Preferences",
								requiresAuth: true,
							},
						},
						{
							path: "modifypassword",
							name: "modifypassword",
							props: true,
							component: () => import("../views/userarea/modifypassword.vue"),
							meta: {
								title:
									"Albini Group Online Shop - Area utente - Modifica password",
								titleEN:
									"Albini Group Online Shop - User Area - Modify password",
								requiresAuth: true,
							},
						},
						{
							path: "pricelist",
							name: "pricelist",
							props: true,
							component: () => import("../views/userarea/pricelist.vue"),
							meta: {
								title: "Albini Group Online Shop - Area utente - Price list",
								titleEN: "Albini Group Online Shop - User Area - Price list",
								requiresAuth: true,
							},
						},
						{
							path: "program",
							name: "program",
							props: true,
							component: () => import("../views/userarea/program.vue"),
							meta: {
								title: "Albini Group Online Shop - Area utente - Programma",
								titleEN: "Albini Group Online Shop - User Area - Program",
								requiresAuth: true,
							},
						},
						{
							path: "library",
							name: "library",
							props: true,
							component: () => import("../views/userarea/library.vue"),
							meta: {
								title: "Albini Group Online Shop - Area utente - Libreria",
								titleEN: "Albini Group Online Shop - User Area - Library",
								requiresAuth: true,
							},
						},
						{
							path: "digitalcollection",
							name: "digitalcollection",
							props: true,
							component: () =>
								import("../views/userarea/digitalcollection.vue"),
							meta: {
								title:
									"Albini Group Online Shop - Area utente - Collezione Digitale",
								titleEN:
									"Albini Group Online Shop - User Area - Digital Collection",
								requiresAuth: true,
							},
						},
						{
							path: "historydownload",
							name: "historydownload",
							props: true,
							component: () => import("../views/userarea/historydownload.vue"),
							meta: {
								title:
									"Albini Group Online Shop - Area utente - Storico Download",
								titleEN:
									"Albini Group Online Shop - User Area - History Download",
								requiresAuth: true,
							},
						},
						{
							path: "businessintelligence",
							name: "businessintelligence",
							props: true,
							component: () =>
								import("../views/userarea/businessintelligence.vue"),
							meta: {
								title:
									"Albini Group Online Shop - Area utente - Business intelligence",
								titleEN:
									"Albini Group Online Shop - User Area - Business intelligence",
								requiresAuth: true,
							},
						},
						{
							path: "orders",
							name: "orders",
							props: true,
							component: () => import("../views/userarea/orders.vue"),
							meta: {
								title:
									"Albini Group Online Shop - Area utente - Storico ordini",
								titleEN: "Albini Group Online Shop - User Area - Orders list",
								requiresAuth: true,
							},
						},
						{
							path: "orders/:idorder",
							name: "ordersdetail",
							props: true,
							component: () => import("../views/userarea/ordersdetail.vue"),
							meta: {
								title:
									"Albini Group Online Shop - Area utente - Dettaglio ordine",
								titleEN: "Albini Group Online Shop - User Area - Order detail",
								requiresAuth: true,
							},
						},
						{
							path: "invoices",
							name: "invoices",
							props: true,
							component: () => import("../views/userarea/invoices.vue"),
							meta: {
								title: "Albini Group Online Shop - Area utente - Fatture",
								titleEN: "Albini Group Online Shop - User Area - Invoices",
								requiresAuth: true,
							},
						},
						{
							path: "wishlists",
							name: "wishlists",
							props: true,
							component: () => import("../views/userarea/wishlists.vue"),
							meta: {
								title: "Albini Group Online Shop - Area utente - Wishlists",
								titleEN: "Albini Group Online Shop - User Area - Wishlists",
								requiresAuth: true,
							},
						},
						{
							path: "wishlists/:id",
							name: "wishlistdetail",
							props: true,
							component: () => import("../views/userarea/wishlistdetail.vue"),
							meta: {
								title:
									"Albini Group Online Shop - Area utente - Dettaglio wishlist",
								titleEN:
									"Albini Group Online Shop - User Area - Wishlist detail",
								requiresAuth: true,
							},
						},
					],
				},
			],
		},
		{
			path: "/:pathMatch(.*)*",
			name: "NotFound",
			component: () => import("../views/notfound.vue"),
		},
	],
});

/**
 * Check user authentication.
 * If user is not logged return to Login Page
 */
router.beforeEach((to, from, next) => {
	let token = $cookies.get("AlbiniSHOPTKN");
	if (to.params.locale) {
		if (to.params.locale == "it") {
			const nearestWithTitle = to.matched
				.slice()
				.reverse()
				.find((r) => r.meta && r.meta.title);
			if (nearestWithTitle) {
				document.title = nearestWithTitle.meta.title;
			}
		} else {
			const nearestWithTitle = to.matched
				.slice()
				.reverse()
				.find((r) => r.meta && r.meta.titleEN);
			if (nearestWithTitle) {
				document.title = nearestWithTitle.meta.titleEN;
			}
		}
	} else {
		const nearestWithTitle = to.matched
			.slice()
			.reverse()
			.find((r) => r.meta && r.meta.titleEN);
		if (nearestWithTitle) {
			document.title = nearestWithTitle.meta.titleEN;
		}
	}

	if (to.meta.requiresAuth && !token) {
		localStorage.currentPath = encodeURIComponent(to.fullPath);
		if (localStorage.currentPath.indexOf("welcome") > -1) {
			localStorage.currentPath = "";
		}

		const loc = to.params.locale || "en";
		$cookies.set("AlbiniSHOPLANG", loc);
		let url = `/${loc}/login`;

		if (localStorage.currentPath !== "") {
			url += `?urlPath=${localStorage.currentPath}`;
			if (localStorage.forceDitta?.length) {
				url += `&forceDitta=${localStorage.forceDitta}`;
			}
		} else {
			if (localStorage.forceDitta?.length) {
				url += `?forceDitta=${localStorage.forceDitta}`;
			}
		}
		delete localStorage.forceDitta; // remove prop from localstorage

		window.location.href = url;
	} else {
		delete localStorage.forceDitta; // remove prop from localstorage
		const localee = to.params.locale;
		if (
			localee != "" &&
			localee != null &&
			localee != "undefined" &&
			localee != undefined
		) {
			switch (localee) {
				case "en":
					$cookies.set("AlbiniSHOPLANG", "en");
					document.querySelector("html").setAttribute("lang", "en-EN");
					break;
				case "de":
					$cookies.set("AlbiniSHOPLANG", "de");
					document.querySelector("html").setAttribute("lang", "de-DE");
					break;
				case "fr":
					$cookies.set("AlbiniSHOPLANG", "fr");
					document.querySelector("html").setAttribute("lang", "fr-FR");
					break;
				case "es":
					$cookies.set("AlbiniSHOPLANG", "es");
					document.querySelector("html").setAttribute("lang", "es-ES");
					break;
				case "zh":
					$cookies.set("AlbiniSHOPLANG", "zh");
					document.querySelector("html").setAttribute("lang", "zh-CH");
					break;
				case "it":
					$cookies.set("AlbiniSHOPLANG", "it");
					document.querySelector("html").setAttribute("lang", "it-IT");
					break;
				default:
					$cookies.set("AlbiniSHOPLANG", "en");
					document.querySelector("html").setAttribute("lang", "en-EN");
					break;
			}
		} else {
			$cookies.set("AlbiniSHOPLANG", "en");
			document.querySelector("html").setAttribute("lang", "en-EN");
		}
		let a = to.fullPath.split("/");
		if (a.length < 3) {
			if (to.params.locale) {
				$cookies.set("AlbiniSHOPLANG", to.params.locale);
				document
					.querySelector("html")
					.setAttribute(
						"lang",
						to.params.locale + "-" + to.params.locale.toUpperCase()
					);
				window.location.href = "/" + to.params.locale + "/welcome";
				document.body.classList.remove("modalOpened");
			} else {
				$cookies.set("AlbiniSHOPLANG", "en");
				document.querySelector("html").setAttribute("lang", "en-EN");
				window.location.href = "/en/welcome";
				document.body.classList.remove("modalOpened");
			}
		} else {
			next();
			document.body.classList.remove("modalOpened");
		}
	}
});
export default router;
