import { BehaviorSubject } from "rxjs";
import UserApi from "../commons/api/user-api";

const currentUserToken = localStorage.getItem("AlbiniSHOPUSER");
const currentUserSubject = new BehaviorSubject(
	currentUserToken ? JSON.parse(currentUserToken) : null
);

export const AuthenticationService = {
	login,
	logout,
	currentUser: currentUserSubject.asObservable(),
	get currentUserValue() {
		return currentUserSubject.value;
	},
	get currentUserDitta() {
		return currentUserToken
			? currentUserSubject.value.currentCustomer.ditta
			: "";
	},
	get userAuthorizations() {
		return currentUserToken ? currentUserSubject.value.authorizations : "";
	},
};

function login(username, password, forceDitta) {
	return new Promise((resolve, reject) => {
		UserApi.login(
			{
				username: username,
				password: password,
			},
			forceDitta
		)
			.then((response) => {
				if (response.status === 200) {
					let userToken = response.data.userToken;
					let userId = response.data.userId;
					let userDetail = response.data.userDetail;

					currentUserSubject.next(userDetail);

					$cookies.set("AlbiniSHOPTKN", userToken, "30d");
					$cookies.set(
						"AlbiniSHOPLANG",
						userDetail.languageCode.toLowerCase(),
						"30d"
					);
					localStorage.setItem("AlbiniSHOPUSER", JSON.stringify(userDetail));
					resolve(response);
				} else {
					reject(response);
				}
			})
			.catch((e) => {
				reject(e);
			});
	});
}

function logout() {
	$cookies.remove("AlbiniSHOPTKN");
	localStorage.removeItem("AlbiniSHOPUSER");
	currentUserSubject.next(null);
}

function getCookie(cname) {
	let name = cname + "=";
	let decodedCookie = decodeURIComponent(document.cookie);
	let ca = decodedCookie.split(";");
	for (let i = 0; i < ca.length; i++) {
		let c = ca[i];
		while (c.charAt(0) == " ") {
			c = c.substring(1);
		}
		if (c.indexOf(name) == 0) {
			return c.substring(name.length, c.length);
		}
	}
	return "";
}
