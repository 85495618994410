import { HTTP } from "./http-common";
import BaseApi from "./http-common";

export default {
  search(
    page,
    sortBy,
    size,
    descending,
    filters
  ) {

    let sortString = '';
    if(sortBy != '' && sortBy != null){
      sortString +=sortBy+',';
    }else{
      sortString += 'id,';
    }

    if(descending){
      sortString+='desc'
    }else{
      sortString+='asc'
    }

    let searchParams = {
      page: page,
      size: size ? size : 10,
      sort: sortString,
    };

    // map filters into query params
    if (filters) {

      Object.entries(filters).forEach(([key, value]) => {
        if (value) {
          searchParams[key] = value
        }
      })
    }
    
    return BaseApi.search("/articles/filters", {
      params: searchParams,
    });
  },

  getArticleById(id) {
    return BaseApi.get("/articles/", id);
  },

  getArticleByCode(code) {
    return BaseApi.get("/articles/code/", code);
  },

  getArticlesByCodeAndColorArray(params){
    let searchParams = {
      cc : params
    }
    return BaseApi.search("/articles", {
      params: searchParams,
    });
  },

  notifyMe(code,color,data){
    return BaseApi.create("/articles/code/"+code+"/"+color+"/notifyme",data);
  },

  searchFullText(
    page,
    sortBy,
    size,
    descending,
    term
  ){
    let sortString = '';
    if(sortBy != '' && sortBy != null){
      sortString +=sortBy+',';
    }else{
      sortString += 'code,';
    }

    if(descending){
      sortString+='desc'
    }else{
      sortString+='asc'
    }

    let searchParams = {
      page: page,
      size: size ? size : 10,
      sort: sortString,
    };
    if (term) {
      searchParams["search"] = term;
    }
    return BaseApi.search("/articles/full-text",{
        params: searchParams,
    });
  },

  getRelatedArticlesById(id){
    return BaseApi.getpath("/articles/",id,"/related");
  },

  getArticleByCodeAndColor(code,color){
    return BaseApi.getpath("/articles/code/",code,"/"+color);
  },

  getAllArticleAccessoriesByCodeAndColor(code,color){
    return BaseApi.getpath("/articles/code/",code,"/"+color+"/accessories");
  },

  getRelatedArticlesByCodeAndColor(code,color){
    return BaseApi.getpath("/articles/code/",code,"/"+color+"/related");
  },

  getArticleAvailability(code,color,searchParams){
    return BaseApi.search("/articles/availability/"+code+"/"+color,{
        params: searchParams,
    });
  },

  downloadImageArchive(params) {
    return BaseApi.createWithParams('/articles/digital-collection/generate-image-archive', null, params)
  },

  exportCsv(params) {
    return BaseApi.createWithParams('/articles/digital-collection/generate-fabric-csv', null, params)
  },

  getDigitalCollection(params) {
    return BaseApi.search("/articles/digital-collection", {params});
  },

  downloadHistoryFile(url) {
    return BaseApi.getFullPath(url)
  }

}