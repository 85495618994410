import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment } from "vue";
var _hoisted_1 = {
  key: 0,
  class: "common-layout"
};
var _hoisted_2 = {
  key: 1,
  class: "common-layout"
};
var _hoisted_3 = {
  key: 2,
  class: "common-layout"
};
var _hoisted_4 = {
  class: "w-100 d-flex justify-content-center"
};
var _hoisted_5 = ["src"];
var _hoisted_6 = {
  key: 0,
  class: "w-100 text-form d-flex flex-column align-items-center text-center"
};
var _hoisted_7 = ["innerHTML"];
var _hoisted_8 = ["src"];
var _hoisted_9 = {
  class: "font-weight-bold"
};
var _hoisted_10 = ["src"];
var _hoisted_11 = {
  class: "font-weight-bold"
};
var _hoisted_12 = {
  key: 1,
  class: "w-100 text-form d-flex flex-column"
};
var _hoisted_13 = ["innerHTML"];
var _hoisted_14 = {
  key: 2,
  class: "w-100 text-form d-flex flex-column align-items-center"
};
var _hoisted_15 = ["innerHTML"];
var _hoisted_16 = {
  key: 3,
  class: "w-100 text-form d-flex flex-column align-items-center"
};
var _hoisted_17 = ["innerHTML"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_RouterView = _resolveComponent("RouterView");
  var _component_el_config_provider = _resolveComponent("el-config-provider");
  var _component_el_main = _resolveComponent("el-main");
  var _component_HeaderApp = _resolveComponent("HeaderApp");
  var _component_FooterApp = _resolveComponent("FooterApp");
  var _component_el_container = _resolveComponent("el-container");
  var _component_el_col = _resolveComponent("el-col");
  var _component_el_row = _resolveComponent("el-row");
  var _component_el_button = _resolveComponent("el-button");
  var _component_el_dialog = _resolveComponent("el-dialog");
  return _openBlock(), _createElementBlock(_Fragment, null, [$data.noHeaderFooterPages.includes($options.rootname) ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_el_main, null, {
    default: _withCtx(() => [_createVNode(_component_el_config_provider, {
      locale: $setup.localeElementPlus
    }, {
      default: _withCtx(() => [_createVNode(_component_RouterView)]),
      _: 1
    }, 8, ["locale"])]),
    _: 1
  })])) : _createCommentVNode("", true), !$data.noHeaderFooterPages.includes($options.rootname) && !$data.onlyHeaderPages.includes($options.rootname) ? (_openBlock(), _createElementBlock("div", _hoisted_2, [_createVNode(_component_el_container, null, {
    default: _withCtx(() => [(_openBlock(), _createBlock(_component_HeaderApp, {
      pageactive: $options.pageactive,
      key: _ctx.$route.fullPath
    }, null, 8, ["pageactive"])), _createVNode(_component_el_main, null, {
      default: _withCtx(() => [_createVNode(_component_el_config_provider, {
        locale: $setup.localeElementPlus
      }, {
        default: _withCtx(() => [_createVNode(_component_RouterView)]),
        _: 1
      }, 8, ["locale"])]),
      _: 1
    }), _createVNode(_component_FooterApp, {
      pageactive: $options.pageactive
    }, null, 8, ["pageactive"])]),
    _: 1
  })])) : _createCommentVNode("", true), $data.onlyHeaderPages.includes($options.rootname) ? (_openBlock(), _createElementBlock("div", _hoisted_3, [_createVNode(_component_el_container, null, {
    default: _withCtx(() => [(_openBlock(), _createBlock(_component_HeaderApp, {
      pageactive: $options.pageactive,
      key: _ctx.$route.fullPath
    }, null, 8, ["pageactive"]))]),
    _: 1
  }), _createVNode(_component_el_main, null, {
    default: _withCtx(() => [_createVNode(_component_el_config_provider, {
      locale: $setup.localeElementPlus
    }, {
      default: _withCtx(() => [_createVNode(_component_RouterView)]),
      _: 1
    }, 8, ["locale"])]),
    _: 1
  }), _createVNode(_component_el_container, null, {
    default: _withCtx(() => [_createVNode(_component_FooterApp, {
      pageactive: $options.pageactive
    }, null, 8, ["pageactive"])]),
    _: 1
  })])) : _createCommentVNode("", true), $data.infoFlags ? (_openBlock(), _createBlock(_component_el_dialog, {
    key: 3,
    modelValue: $data.infoDialog,
    "onUpdate:modelValue": _cache[5] || (_cache[5] = $event => $data.infoDialog = $event),
    width: _ctx.$root.mobile ? '90%' : '50%',
    "append-to-body": true,
    "show-close": !$data.infoFlags.showPreferredPackagingChoice,
    "close-on-click-modal": false,
    "close-on-press-escape": false
  }, {
    header: _withCtx(() => [_createElementVNode("span", _hoisted_4, [_createElementVNode("img", {
      src: require('@/assets/logo.png'),
      style: {
        "width": "100px",
        "height": "auto",
        "object-fit": "contain"
      }
    }, null, 8, _hoisted_5)])]),
    default: _withCtx(() => [$data.infoFlags.showPreferredPackagingChoice ? (_openBlock(), _createElementBlock("div", _hoisted_6, [_createElementVNode("span", {
      class: "text-center mb-3",
      innerHTML: _ctx.$t('Per questo account non è ancora stato scelto il packaging predefinito.<br>Clicca su uno di essi per proseguire con la navigazione.')
    }, null, 8, _hoisted_7), _createVNode(_component_el_row, {
      class: "w-100 mx-0 d-flex align-items-center justify-content-between",
      gutter: 50
    }, {
      default: _withCtx(() => [_createVNode(_component_el_col, {
        span: 12,
        class: "d-flex flex-column align-items-center",
        style: {
          "cursor": "pointer"
        },
        onClick: _cache[0] || (_cache[0] = $event => $options.selectPreferredPackaging('PACKAGE_NO_COUPON'))
      }, {
        default: _withCtx(() => [_createElementVNode("img", {
          src: require('@/assets/conf-standard.jpg'),
          class: "w-100 mb-2",
          style: {
            "aspect-ratio": "1/1",
            "max-width": "250px"
          }
        }, null, 8, _hoisted_8), _createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.$t('Confezionamento standard')), 1)]),
        _: 1
      }), _createVNode(_component_el_col, {
        span: 12,
        class: "d-flex flex-column align-items-center",
        style: {
          "cursor": "pointer"
        },
        onClick: _cache[1] || (_cache[1] = $event => $options.selectPreferredPackaging('PACKAGE_COUPON'))
      }, {
        default: _withCtx(() => [_createElementVNode("img", {
          src: require('@/assets/conf-coupon.jpg'),
          class: "w-100 mb-2",
          style: {
            "aspect-ratio": "1/1",
            "max-width": "250px"
          }
        }, null, 8, _hoisted_10), _createElementVNode("span", _hoisted_11, _toDisplayString(_ctx.$t('Confezionamento a coupon')), 1)]),
        _: 1
      })]),
      _: 1
    })])) : $data.infoFlags.showFirstExpiredInvoicesReminder ? (_openBlock(), _createElementBlock("div", _hoisted_12, [_createElementVNode("span", {
      innerHTML: _ctx.$t('FIRST_INVOICES_REMINDER', {
        bank: $data.userDetail?.currentCustomer?.albiniBank,
        iban: $data.userDetail?.currentCustomer?.albiniIban
      })
    }, null, 8, _hoisted_13), _createVNode(_component_el_button, {
      onClick: _cache[2] || (_cache[2] = $event => $options.navigateToInvoices()),
      type: "primary",
      class: "mx-auto"
    }, {
      default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.$t('VAI AL DETTAGLIO')), 1)]),
      _: 1
    })])) : $data.infoFlags.showSecondExpiredInvoicesReminder ? (_openBlock(), _createElementBlock("div", _hoisted_14, [_createElementVNode("span", {
      innerHTML: _ctx.$t('SECOND_INVOICES_REMINDER', {
        bank: $data.userDetail?.currentCustomer?.albiniBank,
        iban: $data.userDetail?.currentCustomer?.albiniIban
      })
    }, null, 8, _hoisted_15), _createVNode(_component_el_button, {
      onClick: _cache[3] || (_cache[3] = $event => $options.navigateToInvoices()),
      type: "primary",
      class: "mx-auto"
    }, {
      default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.$t('VAI AL DETTAGLIO')), 1)]),
      _: 1
    })])) : $data.infoFlags.showSavedCartReminder ? (_openBlock(), _createElementBlock("div", _hoisted_16, [_createElementVNode("span", {
      innerHTML: _ctx.$t('SAVED_CART_REMINDER')
    }, null, 8, _hoisted_17), _createVNode(_component_el_button, {
      onClick: _cache[4] || (_cache[4] = $event => $options.navigateToOrders()),
      type: "primary"
    }, {
      default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.$t('VAI AGLI ACQUISTI')), 1)]),
      _: 1
    })])) : _createCommentVNode("", true)]),
    _: 1
  }, 8, ["modelValue", "width", "show-close"])) : _createCommentVNode("", true)], 64);
}