import { createApp } from "vue";
import App from "./App.vue";
import urql from "@urql/vue";
import { dedupExchange, fetchExchange } from "@urql/vue";
import { cacheExchange } from "@urql/exchange-graphcache";
import router from "./router";
import axios from "axios";
import VueAxios from "vue-axios";
import moment from "moment";
import { createI18n } from "vue-i18n";
import it_locale from "./i18n/it_IT.json";
import en_locale from "./i18n/en_US.json";
import de_locale from "./i18n/de_DE.json";
import fr_locale from "./i18n/fr_FR.json";
import es_locale from "./i18n/es_ES.json";
import zh_locale from "./i18n/zh_CH.json";
import VueCookies from "vue-cookies";
import ElementPlus from "element-plus";
import StoragePlugin from "vue-web-storage";
import * as ElementPlusIconsVue from "@element-plus/icons-vue";

import "element-plus/theme-chalk/display.css";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/effect-fade";
import "swiper/css/lazy";
import "element-plus/dist/index.css";
import "./css/_bootstrap.scss";
import "./css/_style.scss";
import "./css/_mediaquery.scss";
const i18n = createI18n({
	messages: {
		it: it_locale,
		en: en_locale,
		de: de_locale,
		fr: fr_locale,
		es: es_locale,
		zh: zh_locale,
	},
	legacy: false,
	globalInjection: true,
	silentTranslationWarn: true,
	locale: "en",
	fallbackLocale: "en",
});

const app = createApp(App);
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
	app.component(key, component);
}
app.use(i18n);
app.use(router);
app.use(StoragePlugin);
app.use(VueAxios, axios);
app.use(urql, {
	url: process.env.VUE_APP_GRAPHQL_URL,
	requestPolicy: "network-only",
	credentials: false,
	exchanges: [dedupExchange, fetchExchange, cacheExchange({})],
	maskTypename: true,
});
app.use(moment);
app.use(ElementPlus);
app.use(VueCookies, { expire: "30d" });

app.config.globalProperties.$filters = {
	getDittaLabel(value) {
		if (value) {
			switch (value) {
				case "002":
					return "Bespoke";
				case "PRD":
					return "Production";
				case "HGK":
					return "Bespoke HK";
				case "STK":
					return "Promotions";
				default:
					return "";
			}
		} else return "";
	},
};

app.mount("#app");
