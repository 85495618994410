import "core-js/modules/es.array.push.js";
import { h, provide } from "vue";
import { RouterView } from "vue-router";
import axios from "axios";
import HeaderApp from "@/components/header.vue";
import FooterApp from "@/components/footer.vue";
import { AuthenticationService } from "./commons/authentication";
import CartApi from "./commons/api/cart-api";
import UserApi from "./commons/api/user-api";
import { ElCheckbox, ElMessageBox, ElConfigProvider } from 'element-plus';
import it from 'element-plus/dist/locale/it.mjs';
import en from 'element-plus/dist/locale/en.mjs';
import { useI18n } from 'vue-i18n';
export default {
  name: 'App',
  data() {
    return {
      userLogged: AuthenticationService.currentUserValue,
      userRole: AuthenticationService.currentUserDitta,
      // PRD,002,HGK,STK
      userAuthorizations: AuthenticationService.userAuthorizations,
      // PRD,002,HGK,STK
      language: "",
      totals: {},
      api: process.env.VUE_APP_URLAPI,
      env: process.env.VUE_APP_ENV,
      pathcms: process.env.VUE_APP_URLCMS,
      path: process.env.VUE_APP_URL,
      publicPath: process.env.BASE_URL,
      mobile: false,
      smalldesktop: false,
      xmobile: false,
      tourAvailable: true,
      thispage: this.$router.currentRoute.value.name,
      infoDialog: false,
      infoFlags: null,
      userDetail: null,
      noHeaderFooterPages: ['impersonate', 'resetpassword', 'maintenance', 'fastpayment', 'public-catalogue', 'public-product', 'notfound'],
      onlyHeaderPages: ['login', 'insightsdetail']
    };
  },
  components: {
    HeaderApp,
    ElConfigProvider,
    FooterApp
  },
  setup() {
    var {
      setLocaleMessage
    } = useI18n({
      useScope: 'global'
    });
    var cookieLang = $cookies.get("AlbiniSHOPLANG");
    var localeElementPlus = cookieLang === 'it' ? it : en;

    // load cookie locale
    switch (cookieLang) {
      case "en":
        axios.get(process.env.VUE_APP_ENLABEL).then(function (response) {
          setLocaleMessage("en", response.data);
        }).catch(function (error) {});
        break;
      case "fr":
        axios.get(process.env.VUE_APP_FRLABEL).then(function (response) {
          setLocaleMessage("fr", response.data);
        }).catch(function (error) {});
        break;
      case "es":
        axios.get(process.env.VUE_APP_ESLABEL).then(function (response) {
          setLocaleMessage("es", response.data);
        }).catch(function (error) {});
        break;
      case "zh":
        axios.get(process.env.VUE_APP_ZHLABEL).then(function (response) {
          setLocaleMessage("zh", response.data);
        }).catch(function (error) {});
        break;
      case "it":
        axios.get(process.env.VUE_APP_ITLABEL).then(function (response) {
          setLocaleMessage("it", response.data);
        }).catch(function (error) {});
        break;
      case "de":
        axios.get(process.env.VUE_APP_DELABEL).then(function (response) {
          setLocaleMessage("de", response.data);
        }).catch(function (error) {});
    }
    return {
      cookieLang,
      localeElementPlus
    };
  },
  computed: {
    pageactive() {
      return this.$route?.path;
    },
    rootname() {
      return this.$route?.name;
    }
  },
  async created() {
    provide('userAuthorizations', this.userAuthorizations);
    if (screen.width < 1600) {
      this.smalldesktop = true;
    }
    if (screen.width < 1250) {
      this.mobile = true;
    }
    if (screen.width < 800) {
      this.xmobile = true;
    }
    if ($cookies.isKey("AlbiniSHOPTKN")) {
      this.userLogged = true;
    } else {
      this.userLogged = false;
    }
  },
  async mounted() {
    document.body.classList.remove('modalOpened');

    //pageactive.value = route.path
    await this.$router.isReady();

    // if route lang param considerations is different from cookie prev locale, reload
    if (this.cookieLang !== $cookies.get("AlbiniSHOPLANG")) {
      // force reload -> schifo!
      window.location.reload();
    } else {
      if ($cookies.isKey("AlbiniSHOPLANG")) {
        this.$i18n.locale = $cookies.get("AlbiniSHOPLANG");
        this.language = $cookies.get("AlbiniSHOPLANG");
      } else {
        this.$i18n.locale = 'en';
        this.language = 'en';
        $cookies.set("AlbiniSHOPLANG", "en");
      }
      provide('language', this.language);
      this.localeElementPlus = this.language === 'it' ? it : en;
    }
    if (this.rootname !== 'maintenance') {
      if (!$cookies.isKey("AlbiniSHOPGUIDE") && this.userLogged && this.tourAvailable) {
        ElMessageBox({
          title: this.$t('Tour guidato del sito'),
          message: () => h('div', null, [h('p', {
            class: 'm-0 mb-2 text-medium'
          }, this.$t('Prima di iniziare a utilizzare lo shop, scopri tutte le sue funzionalità grazie al nostro tour guidato.')), h(ElCheckbox, {
            id: 'checkBoxCookieMessage',
            'onUpdate:modelValue': val => {
              if (val) {
                $cookies.set("AlbiniSHOPGUIDE", "novisited", "100000d");
              } else {
                $cookies.remove("AlbiniSHOPGUIDE");
              }
            },
            size: 'large'
          }, this.$t('Non mostrare più'))]),
          dangerouslyUseHTMLString: true,
          distinguishCancelAndClose: true,
          confirmButtonText: this.$t('Inizia il tour'),
          cancelButtonText: this.$t('Richiedimelo domani'),
          showClose: true,
          customClass: 'tourMessageBox',
          showCancelButton: true,
          type: 'info',
          cancelButtonClass: 'bg-grey',
          closeOnHashChange: false,
          closeOnPressEscape: false,
          closeOnClickModal: false,
          buttonSize: 'small'
        }).then(() => {
          $cookies.set("AlbiniSHOPGUIDE", "visited");
          window.location.href = "/" + this.language + "/guide-tour";
        }).catch(action => {
          if (action === 'cancel') {
            $cookies.set("AlbiniSHOPGUIDE", "novisited", "1d");
          } else {
            return false;
          }
        });
      }
    }
    if ($cookies.get("AlbiniSHOPTKN")) {
      if (!this.noHeaderFooterPages.includes(this.rootname) && !this.onlyHeaderPages.includes(this.rootname)) {
        CartApi.getCartTotals().then(response => {
          this.totals = response.data;
        }).catch(e => {
          console.log(e);
          this.$message.error(e.message);
        });

        // show info only first time after login or customer change
        if (!!localStorage.getItem('infoModals')) {
          this.userDetail = JSON.parse(localStorage.getItem('AlbiniSHOPUSER'));
          this.infoFlags = JSON.parse(localStorage.getItem('infoModals'));
          this.infoDialog = true;
          localStorage.removeItem('infoModals');
        }
      }
    }
  },
  methods: {
    navigateToOrders() {
      this.infoDialog = false; // close dialog
      this.$router.push({
        name: 'orders'
      });
    },
    navigateToInvoices() {
      this.infoDialog = false; // close dialog
      this.$router.push({
        name: 'invoices'
      });
    },
    selectPreferredPackaging(type) {
      UserApi.selectPreferredPackaging(type).then(response => {
        this.infoDialog = false; // close dialog

        //if not in home, send back to homepage
        if (this.rootname !== 'homepage') {
          this.$router.push({
            name: 'homepage'
          });
        }
      }).catch(e => {
        console.log(e);
        this.$message.error(e.message);
      });
    }
  }
};