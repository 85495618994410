import { HTTP } from "./http-common";
import BaseApi from "./http-common";

export default {
	get(id) {
		return BaseApi.get("/users/", id);
	},

	getClients() {
		return BaseApi.search("/clients/");
	},

	login(data, forceDitta) {
		if (forceDitta) {
			return BaseApi.createWithParams("/users/login", data, {
				forceDitta,
			});
		} else {
			return BaseApi.create("/users/login", data);
		}
	},

	logout(data) {
		return BaseApi.create("/users/logout", data);
	},

	changePassword(data) {
		return BaseApi.update("/users/", "change-password", data);
	},

	forgotPassword(data) {
		return BaseApi.create("/users/forgot-password", data);
	},

	switchCustomer(data) {
		return BaseApi.create("/users/switch-customer", data);
	},

	checkToken(token, data) {
		return BaseApi.create("/users/verify-token/" + token + "/data/" + data, {});
	},

	resetPassword(token, checkData, data) {
		return BaseApi.create(
			"/users/forgot-password/" + token + "/data/" + checkData,
			data
		);
	},
	selectPreferredPackaging(code) {
		return BaseApi.update("/users/", "preferred-packaging ", { code });
	},
};
