import "core-js/modules/es.array.push.js";
import { useQuery } from '@urql/vue';
import { defineComponent, ref, onMounted } from 'vue';
import { Search } from '@element-plus/icons-vue';
import axios from "axios";
import UserApi from "../commons/api/user-api";
import ArticlesApi from "../commons/api/article-api";
import { ElMessageBox, ElNotification } from 'element-plus';
export default defineComponent({
  name: 'AppHeader',
  props: {
    pageactive: String
  },
  setup() {
    var activeLang = $cookies.get("AlbiniSHOPLANG").toString();
    var result = useQuery({
      query: `
        {
        header(locale:"` + $cookies.get("AlbiniSHOPLANG") + `"){
            data{
                attributes{
                    logo_header{
                        data{
                            attributes{
                                url
                            }
                        }
                    }
                    links{
                        link_title
                        link_url
                    }
                    message{
                    showmessage
                    messagetxt
                    }
                }
            }
        }
        }`
    });
    var {
      __typename,
      ...rest
    } = result;
    return {
      fetchingheader: result.fetching,
      dataheader: result.data ? result.data : null,
      errorheader: result.error,
      activeLang
    };
  },
  data() {
    return {
      userDetail: JSON.parse(localStorage.getItem("AlbiniSHOPUSER")),
      searchDiv: false,
      mobileMenu: false,
      inputSearch: ''
    };
  },
  computed: {
    currentDitta() {
      return this.userDetail?.currentCustomer?.ditta || '';
    }
  },
  methods: {
    toggleMobileMenu() {
      this.mobileMenu = !this.mobileMenu;
      if (this.mobileMenu) {
        document.body.classList.add('modalOpened');
      } else {
        document.body.classList.remove('modalOpened');
      }
    },
    onEnter(event) {
      event.stopPropagation();
      event.preventDefault();
      //window.location.href = "/" + this.$root.language + "/search?q=" + this.inputSearch;
      this.$router.push({
        path: `/${this.$root.language}/search`,
        query: {
          q: this.inputSearch
        }
      });
    },
    changeclient(code, ditta, name) {
      ElMessageBox.confirm(this.$t('Stai cambiando il cliente con:') + '<br/><b>' + name + '</b><br/>' + this.$t(' confermi?'), {
        dangerouslyUseHTMLString: true,
        customClass: 'change-customer-confirm'
      }).then(() => {
        var loading = this.$loading({
          fullscreen: true,
          lock: true
        });
        var params = {
          ditta: ditta,
          customerCode: code
        };
        UserApi.switchCustomer(params).then(response => {
          var res = response.data;
          $cookies.remove("AlbiniSHOPTKN");
          localStorage.removeItem("AlbiniSHOPUSER");
          localStorage.removeItem("AlbiniSHOPFILTERS"); //clear filters
          var userToken = res.userToken;
          var userDetail = res.userDetail;
          $cookies.set("AlbiniSHOPTKN", userToken, "30d");
          localStorage.setItem("AlbiniSHOPUSER", JSON.stringify(userDetail));
          if (res.showFirstExpiredInvoicesReminder || res.showPreferredPackagingChoice || res.showSavedCartReminder || res.showSecondExpiredInvoicesReminder) {
            localStorage.setItem("infoModals", JSON.stringify({
              showFirstExpiredInvoicesReminder: res.showFirstExpiredInvoicesReminder,
              showPreferredPackagingChoice: res.showPreferredPackagingChoice,
              showSavedCartReminder: res.showSavedCartReminder,
              showSecondExpiredInvoicesReminder: res.showSecondExpiredInvoicesReminder
            }));
          }
          // needed to notifify to app component to open info dialogs (on mounted)
          window.location.reload();
        }).catch(e => {
          console.log(e);
          this.$message.error('Something went wrong while switching customer');
        }).finally(() => {
          loading.close();
        });
      }).catch(e => {
        console.log(e);
      });
    },
    goToProduct(code, color) {
      //window.location.href = "/" + this.$root.language + "/product/" + code + "-" + color;
      this.$router.push({
        name: 'productdetail',
        params: {
          prodid: `${code}-${color}`
        }
      });
    },
    logout() {
      UserApi.logout().then(response => {
        $cookies.remove("AlbiniSHOPTKN");
        localStorage.removeItem("AlbiniSHOPUSER");
        this.$root.userLogged = false;
        //window.location.href = "/" + _this.activeLang + "/login";
        this.$router.push({
          name: 'login'
        });
      }).catch(e => {
        this.$message.error('Oops, this is a error message.');
      });
    },
    setLang(lang) {
      var _this = this;
      if ($cookies.get("AlbiniSHOPLANG") !== lang) {
        $cookies.set("AlbiniSHOPLANG", lang);
        _this.$i18n.locale = lang;
        var currentPathObject = _this.$router.currentRoute.value;
        _this.activeLang = lang;
        if (currentPathObject.name === 'insightsdetail') {
          window.location.href = "/" + lang + "/stories";
        } else {
          _this.$router.push({
            name: currentPathObject.name,
            path: "/" + lang + "/" + currentPathObject.path.substr(4, currentPathObject.path.length),
            params: {
              locale: lang
            }
          });
          setTimeout(function () {
            window.location.reload();
          }, 500);
        }
      } else {
        return false;
      }
    },
    openSearchDiv() {
      this.searchDiv = !this.searchDiv;
      if (this.searchDiv) {
        setTimeout(() => {
          this.$refs?.refSearch?.focus();
        }, 300);
      }
    },
    changeDitta(code, ditta, shop = true) {
      var loading = this.$loading({
        fullscreen: true,
        lock: true
      });
      var params = {
        ditta: ditta,
        customerCode: code
      };
      UserApi.switchCustomer(params).then(response => {
        $cookies.remove("AlbiniSHOPTKN");
        localStorage.removeItem("AlbiniSHOPUSER");
        localStorage.removeItem("AlbiniSHOPFILTERS"); //clear filters
        var userToken = response.data.userToken;
        this.userDetail = response.data.userDetail;
        $cookies.set("AlbiniSHOPTKN", userToken, "30d");
        localStorage.setItem("AlbiniSHOPUSER", JSON.stringify(this.userDetail));

        // router navigation doesn't work
        if (shop) {
          //this.$router.push({ name: 'catalog', query: { code: ditta } })
          window.location.href = `/${this.$root.language}/shop`;
        } else {
          //this.$router.push({ name: 'virtualshowroom' })
          window.location.href = `/${this.$root.language}/virtual-show-room`;
        }
      }).catch(e => {
        console.log(e);
        this.$message.error('Oops, this is a error message.');
      }).finally(() => {
        loading.close();
      });
    },
    querySearchAsync(queryString, cb) {
      var results = [];
      if (queryString.length < 3) {
        cb(results);
      } else {
        ArticlesApi.searchFullText(0, null, 100, true, queryString).then(response => {
          cb(response.data.content);
        }).catch(e => {
          console.log(e);
          this.$message.error(e.message);
        });
      }
    },
    checkCustomer(customer) {
      var currentCustomer = this.userDetail?.currentCustomer;
      if (!currentCustomer) return false;
      if (currentCustomer.navigableDitte?.length) {
        if (!customer.navigableDitte?.length) {
          return false;
        } else {
          var dittaCode = customer.navigableDitte.find(d => d.ditta === currentCustomer.ditta);
          return dittaCode.code === currentCustomer.code;
        }
      } else {
        return currentCustomer.ditta === customer.ditta && currentCustomer.code === customer.code;
      }
    }
  },
  watch: {
    $route(to, from) {
      this.mobileMenu = false;
      this.searchDiv = false;
    }
  }
});