import axios from "axios";
/**
 ** Default axios configs
 **/  
export const HTTP = axios.create({
  baseURL: process.env.VUE_APP_URLAPI,
  headers: {
    //Authorization: 'Bearer {token}'
  },
  validateStatus: function (status) {
    return (
      (status >= 200 && status < 300) ||
      status == 400 ||
      status == 401 ||
      status == 403 ||
      status == 500 ||
      status == 404
    );
  },
});

/**
 ** Interceptor for JWT token
 **/
HTTP.interceptors.request.use(function (config) {
  let token = $cookies.get("AlbiniSHOPTKN");
  let lang = $cookies.get("AlbiniSHOPLANG");
  config.headers["language"] = lang;
  if (token) {
    config.headers["Authorization"] = token;
  }
  return config;
});


/**
 ** Interceptor for unhauthorized requests
 **/
 HTTP.interceptors.response.use(
  function (response) {
    if (response != null && (response.status == 401 || response.status == 403)) {
      if ($cookies.isKey("AlbiniSHOPLANG")){
        $cookies.remove("AlbiniSHOPTKN");
        window.location = "/"+$cookies.get("AlbiniSHOPLANG")+"/login";
      } else { 
        $cookies.remove("AlbiniSHOPTKN");
        window.location = "/en/login";
      }
    } else if (response != null && response.status == 500) {
      const lang = $cookies.isKey("AlbiniSHOPLANG") ? $cookies.get("AlbiniSHOPLANG") : 'en'
      // use switch if more languages added
      let message = "Ops! An unexpected error occurred. Please try again later. If the problem persists, contact our customer support for assistance."
      if (lang === "it") {
        message = "Ops! Si è verificato un errore imprevisto. Ti preghiamo di riprovare più tardi. Se il problema persiste, contatta il nostro supporto clienti per assistenza."
      }
      response.data.message = message
    }
    return response;
  },
  function (error) {
    if (error.response?.status === 503) {    
      //maintenance
      if ($cookies.isKey("AlbiniSHOPLANG")){
        window.location.href = "/"+$cookies.get("AlbiniSHOPLANG")+"/maintenance";
      } else {      
        window.location.href = "/en/maintenance";
      }
    } else {
      return Promise.reject(error);
    }
  }
);

export default {
  search(path, params) {
    return new Promise((resolve, reject) => {
      HTTP.get(path, params)
        .then((response) => {
          if (response.status === 200) {
            resolve(response.data);
          } else {
            reject(response.data);
          }
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },

  get(path, id) {
    return new Promise((resolve, reject) => {
      HTTP.get(path + id)
        .then((response) => {
          if (response.status === 200) {
            resolve(response.data);
          } else {
            reject(response.data);
          }
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },

  getpath(path, id, subpath) {
    return new Promise((resolve, reject) => {
      HTTP.get(path + id + subpath)
        .then((response) => {
          if (response.status === 200) {
            resolve(response.data);
          } else {
            reject(response.data);
          }
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },

  update(path, id, data) {
    return new Promise((resolve, reject) => {
      HTTP.put(path + id, data)
        .then((response) => {
          if (response.status === 200) {
            resolve(response.data);
          } else {
            reject(response.data);
          }
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },

  updateWithSubpath(path, id, subpath, data) {
    return new Promise((resolve, reject) => {
      HTTP.put(path + id + subpath, data)
        .then((response) => {
          if (response.status === 200) {
            resolve(response.data);
          } else {
            reject(response.data);
          }
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },

  create(path, data) {
    return new Promise((resolve, reject) => {
      HTTP.post(path, data)
        .then((response) => {
          if (response.status === 200) {
            resolve(response.data);
          } else {
            reject(response.data);
          }
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },

  createWithParams(path, data, params) {
    return new Promise((resolve, reject) => {
      HTTP.post(path, data, {params})
        .then((response) => {
          if (response.status === 200) {
            resolve(response.data);
          } else {
            reject(response.data);
          }
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },


  uploadFile(path, data) {

    let token = $cookies.get("AlbiniSHOPTKN");
    return new Promise((resolve, reject) => {
      axios({
            url: process.env.VUE_APP_URLAPI + path, 
            method: 'POST',
            data,
            headers: {"Authorization": token,"Content-Type": "multipart/form-data"}
        })
        .then((res) => {
            if (res.status === 200) {
              resolve(res);
            } else {
              reject(res);
            }
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
     
      });
  
  },


  delete(path, id) {
    return new Promise((resolve, reject) => {
      HTTP.delete(path + id)
        .then((response) => {
          if (response.status === 200) {
            resolve(response.data);
          } else {
            reject(response.data);
          }
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },

  getFullPath(fullPath) {
    return new Promise((resolve, reject) => { 
      HTTP.get(fullPath)
        .then((response) => {
          if (response.status === 200) {
            resolve(response.data);
          } else {
            reject(response.data);
          }
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    })
  }
};
